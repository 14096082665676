/*-----------------------------------*/
/*--- IMPORT STYLES FOR FASTPAGES ---*/
@import "minima/fastpages-styles";   



/*-----------------------------------*/
/*----- ADD YOUR STYLES BELOW -------*/

// If you want to turn off dark background for syntax highlighting, comment or delete the below line.
@import "minima/fastpages-dracula-highlight"; 
@import "minima/dark-mode";
